function RedirectPage(history,path,defaultPagePath,whiteLabelPath){

    let newPath = path

    if(whiteLabelPath!=null && whiteLabelPath!=undefined){
        newPath = whiteLabelPath;
    }

    if(newPath.length>3){
        let prefix = newPath.substring(0,4);
        if(prefix == "http"){
            window.open(newPath, '_blank', 'noreferrer');
        }else{
            history.push(defaultPagePath)
        }
    }else{
        history.push(defaultPagePath)
    }
    
}

export { RedirectPage };
