function FileForUserGetPartyId() {
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
        let fileForUserDetailsJSON = JSON.parse(fileForUserDetails);
        return fileForUserDetailsJSON.fileForUserPartyId
    } else {
        let userPreferenceJSON = JSON.parse(userPreference);
        if(userPreference){
            return userPreferenceJSON.partyId;
        }else{
            return null;
        }
    }
}

function FileForUserGetUserDetails() {
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
        let fileForUserDetailsJSON = JSON.parse(fileForUserDetails);
        return fileForUserDetailsJSON
    } else {
        return null;
    }
}

function FileForUserGetCurrentUserId() {
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
        let fileForUserDetailsJSON = JSON.parse(fileForUserDetails);
        return fileForUserDetailsJSON.fileForUserId
    } else {
        let userPreferenceJSON = JSON.parse(userPreference);
        return userPreferenceJSON.userId;
    }
}

function FileForUserGetCurrentPartyName() {
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserPartyName = sessionStorage.getItem("fileForUserPartyName");
    if (fileForUserPartyName && fileForUserPartyName != undefined) {
        return fileForUserPartyName
    } else {
        let userPreferenceJSON = JSON.parse(userPreference);
        return userPreferenceJSON.partyName;
    }
}

function FileForUserGetCurrentLoginId() {
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
        let fileForUserDetailsJSON = JSON.parse(fileForUserDetails);
        return fileForUserDetailsJSON.fileForUserLoginId;
    } else {
        let userPreferenceJSON = JSON.parse(userPreference);
        return userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
}

function FileForUserGetUserId() {
    let userPreference = localStorage.getItem("userPreference");
    let userPreferenceJSON = JSON.parse(userPreference);
    return userPreferenceJSON.tprUserDetail.userProfile.loginId;
}

function GetLoggedInUserPartyName() {
    let userPreference = localStorage.getItem("userPreference");
    let userPreferenceJSON = JSON.parse(userPreference);
    return userPreferenceJSON.partyName;
}

function GetLoggedInUserPartyId() {
    let userPreference = localStorage.getItem("userPreference");
    let userPreferenceJSON = JSON.parse(userPreference);
    return userPreferenceJSON.partyId;
}

function FileForUserRemoveAll() {
    sessionStorage.removeItem("fileForUserDetails");
}

function GetFilterByUserIdCriteria(module) {
    let filter = []

        const nangUserProfileJson = sessionStorage.getItem("nangUserProfile")
        const nangUserProfile = nangUserProfileJson ? JSON.parse(nangUserProfileJson) : null

        const nangPartyConfigJson = sessionStorage.getItem("nangPartyConfig")
        const nangPartyConfig = nangPartyConfigJson ? JSON.parse(nangPartyConfigJson) : null

        if (nangPartyConfig != null) {
            for (let i = 0; i < nangPartyConfig.length; i++) {
                var data = nangPartyConfig[i];
                if (data.module == module) {
                    if (data.filterByUserFlag == "true") {
                        let overrides = false;
                        if (nangUserProfile.userPartyConfigs!=null && nangUserProfile.userPartyConfigs.length > 0) {
                            for (let i = 0; i < nangUserProfile.userPartyConfigs.length; i++) {
                                var data = nangUserProfile.userPartyConfigs[i];
                                if (data.module == module && data.disableFilterByUserFlag == "true") {
                                    overrides = true
                                }
                            }
                        }

                        if (overrides == false) {
                            filter = [{ field: 'createdBy', operator: 'equal', value: FileForUserGetCurrentUserId() }];
                        }
                    }
                }
            }
        }
        return filter
    }

export {
    FileForUserGetPartyId,
    FileForUserGetUserDetails,
    FileForUserGetUserId,
    GetLoggedInUserPartyName,
    FileForUserGetCurrentPartyName,
    FileForUserGetCurrentUserId,
    FileForUserGetCurrentLoginId,
    GetLoggedInUserPartyId,
    FileForUserRemoveAll,
    GetFilterByUserIdCriteria
};
