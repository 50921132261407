function AciAceCrewTypeMapping(module, crewType) {
    let crewTypeMap = [
        { aci: 'CRW', ace: 'FM' },
        { aci: 'EJ', ace: 'VW' },
        { aci: 'QF', ace: 'FL' }
    ]

    for (const map of crewTypeMap) {
        if ('ACE' === module && map.aci === crewType) {
            return map.ace
        } else if ('ACI' === module && map.ace === crewType) {
            return map.aci
        }
    }
    return crewType
}

export { AciAceCrewTypeMapping }