const { makeStyles } = require("@material-ui/core");

export const useFormStyles = makeStyles(theme => ({
  form: {
    '& > .MuiStepper-root': {
      padding: theme.spacing(2.5)
    },
    '& *[class*=makeStyles-section]': {
      '& > *[class*=makeStyles-header]': {
        padding: theme.spacing(1, 2),
        '& *': { lineHeight: 1.1 }
      }
    }
  },
  inputs: {
    '& .ng-form-control': {
      '&-label': {
        fontSize: '.9rem'
      },
      '& > .MuiInputBase-input': {
        fontSize: '.9rem'
      }
    }
  }
}))