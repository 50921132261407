const defaultTheme = [
    {
        "name": "LIGHT",
        "typography": {
            "body2": {
                "fontSize": 14
            },
            "button": {
                "fontSize": 14
            },
            "fontFamily": "Roboto,Tahoma,Helvetica,Arial,sans-serif,-apple-system,BlinkMacSystemFont,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""
        },
        "overrides": {
            "MuiDrawer": {
                "paperAnchorDockedLeft": {
                    "borderRight": 0
                }
            },
            "MuiChip": {
                "root": {
                    "backgroundColor": "#F2F1EF",
                    "color": "#121212"
                }
            },
            "MuiLink": {
                "root": {
                    "color": "#000000 !important"
                }
            },
            "MuiTableCell": {
                "head": {
                    "& .ng-form-control.no-label": {
                        "& > input[type=\"text\"].MuiFilledInput-input": {
                            "padding": "8px 4px !important",
                            "fontSize": ".9rem"
                        },
                        "& > .MuiInputAdornment-root": {
                            "&.MuiInputAdornment-positionEnd": { "marginLeft": "0" },
                            "& > .MuiIconButton-root": {
                                "padding": "8px",
                                "& svg": {
                                    "width": ".8em",
                                    "height": ".8em"
                                }
                            }
                        }
                    },
                    ".MuiTableRow-head:not(.table-filter-row) > &": {
                        "padding": "10px 16px !important"
                    },
                    ".table-filter-row &": {
                        "padding": "9.6px",
                        "& .MuiGrid-container": {
                            "flexWrap": "nowrap"
                        }
                    }
                }
            }
        },
        "palette": {
            "type": "light",
            "action": {
                "active": "rgba(0, 0, 0, 0.54)",
                "hover": "rgba(0, 0, 0, 0.04)",
                "selected": "#E7F2F2",
                "disabled": "rgba(0, 0, 0, 0.26)",
                "disabledBackground": "rgba(0, 0, 0, 0.12)",
                "focus": "rgba(0, 0, 0, 0.12)"
            },
            "primary": {
                "main": "#00857D",
                "light": "#fff04e",
                "dark": "#00716a",
                "contrastText": "#ffffff"
            },
            "secondary": {
                "main": "#330000",
                "light": "#eea75b",
                "dark": "#5a0000",
                "contrastText": "#ffffff"
            },
            "success": {
                "main": "#29A855",
                "light": "#2CB55C",
                "dark": "#10602C",
                "contrastText": "#ffffff"
            },
            "error": {
                "main": "#DF2901",
                "light": "#991C00",
                "dark": "#E9694D",
                "contrastText": "#ffffff"
            },
            "warning": {
                "main": "#FBD300",
                "light": "#DFFC30",
                "dark": "#615100",
                "contrastText": "#121212"
            },
            "background": {
                "default": "#ffffff",
                "othersTopbar": "#fff",
                "sectionOddBg": "#f2f1ef",
                "sectionAltBg": "#e5e9f7",
                "dark": "#f2ffff",
                "paper": "#ffffff",
                "stepperActiveFill": "#00857D",
                "stepperNormalText": "#fff",
                "stepperActiveText": "#fff",
                "stepperCompletedText": "#fff",
                "success": "#6ca342",
                "error": "#DF2901",
                "white": "#ffffff"
            },
            "text": {
                "primary": "#121212",
                "secondary": "#5F5753",
                "disabled": "rgba(36, 36, 36, 0.38)",
                "hint": "rgba(36, 36, 36, 0.38)",
                "navSecondary": "#121212",
                "navActive": "#121212",
                "textSecondary": "#121212",
                "textActive": "#121212",
                "error": "#C91A13",
                "success": "#29A855",
                "gray900": "#1C1D21CC",
                "tooltip": "#1C1D21",
                "cngBlack": "#1C1D21",
                "widgetTitle": "#1c1d21",
                "widgetSubTitle": "#7680a3",
                "base": "#121212"
            },
            "color": {
                "primaryColor": "#00857D",
                "primaryDark": "#00716a",
                "primaryGray": "#7680a3",
                "secondaryGreen": "#29A855",
                "secondaryRed": "#ff808b",
                "primaryAzure": "#3300001a",
                "white": "#ffffff"
            },
            "component": {
                "navBar": {
                    "primary": {
                        "active": "#00857d26",
                        "contrastText": "#121212",
                        "hover": "#00857d26"
                    },
                    "secondary": {
                        "active": "#00857d26",
                        "contrastText": "#121212",
                        "hover": "#00857d26"
                    }
                },
                "dropzone": {
                    "normalFill": "#fff",
                    "normalBorder": "#EEEEEE",
                    "hoverborder": "rgba(94, 129, 244, 1)",
                    "hoverFill": "rgba(94, 129, 244, 0.08)",
                    "activeFill": "rgba(94, 129, 244, 0.08)"
                },
                "chart": {
                    "gridLines": "#e8e8e8",
                    "zeroLine": "#a7a7a7",
                    "polarCircleBorder": "#e8e8e8",
                    "radarCircleBorder": "#e8e8e8",
                    "legend": "#1C1D21",
                    "label": "#1C1D21"
                },
                "topBarMegaMenu": {
                    "backgroundFill": "rgba(255, 255, 255, 1)",
                    "gradientStartFill": "rgba(255, 255, 255, 0.5)",
                    "gradientEndFill": "rgba(137, 150, 175, 18%)",
                    "degree": "150deg",
                    "scrollbarFill": "#ffffff",
                    "scrollbarThumbFill": "#7680a3",
                    "scrollbarTrackFill": "#f0f1f5",
                    "lineGradientStartFill": "rgba(255, 255, 255, 0.1)",
                    "lineGradientMidFill": "rgba(137, 150, 175, 0.5)",
                    "lineGradientEndFill": "rgba(255, 255, 255, 0.1)"
                },
                "paper": { "borderColor": "#F5F5FA", "fill": "#FFFFFF" },
                "toggleButton": {
                    "normalFill": "#FFFFFF",
                    "hoverFill": "#ECECF2",
                    "activeFill": "#FFFFFF",
                    "activeBorder": "#ECECF2",
                    "normalText": "#7680a3",
                    "activeText": "#1C1D21"
                },
                "stepper": {
                    "normalFill": "#EFC99E",
                    "activeFill": "#00857D",
                    "completedFill": "#00857D",
                    "normalText": "#1C1D21CC",
                    "activeText": "#1C1D21",
                    "completedText": "#1C1D21CC",
                    "iconFill": "#FFFFFF"
                },
                "pagination": {
                    "btnNormalText": "#1C1D21",
                    "btnHoverFill": "#F5F5FA",
                    "btnActiveFill": "#00857D",
                    "btnActiveText": "#FFFFFF",
                    "btnNavNormalFill": "#F5F5FA",
                    "btnNavNormalText": "#7680a3",
                    "btnNavHoverFill": "#E8E8E8"
                },
                "dropdown": {
                    "normalFill": "rgba(94, 129, 244, 0.019)",
                    "normalBorder": "#EEEEEE",
                    "normalArrowFill": "#6ca342",
                    "placeholderText": "rgba(0, 0, 0, 0.64)",
                    "valueText": "#1C1D21",
                    "hoverFill": "rgba(94, 129, 244, 0.039)",
                    "activeFill": "rgba(94, 129, 244, 0.019)",
                    "activeBorder": "#00857D",
                    "activeArrowFill": "#00857D"
                },
                "hamburger": { "stateClose": "#7680a3", "stateOpen": "#1C1D21" },
                "snackBar": {
                    "displayText": "#FFFFFF",
                    "iconFill": "#FFFFFF",
                    "success": "#29A855",
                    "error": "#DF2901",
                    "info": "#8996af",
                    "warning": "#f67402",
                    "actionIconFill": "#FFFFFF9A"
                },
                "bannerAlert": {
                    "displayText": "#121212",
                    "actionTextLink": "#5F5753",
                    "actionIconFill": "#0a8080",
                    "bannerFill": "#ffc400"
                },
                "checkbox": {
                    "hoverFill": "#E2E2E2",
                    "unCheckHoverFill": "#1C1D210B",
                    "unCheckHoverBorder": "#1C1D2129",
                    "checkHoverFill": "#3300001F",
                    "checkHoverBorder": "#3300004D"
                },
                "switch": {
                    "trackNormalFill": "#f2f1ef",
                    "trackNormalDisabledFill": "#f2f1ef65",
                    "trackNormalDisabledBorder": "#f2f1ef4D",
                    "trackActiveFill": "#0a8080",
                    "trackActiveDisabledFill": "#0a808080",
                    "thumbFill": "#FFFFFF",
                    "thumbBorder": "#1C1D2133",
                    "thumbDisabledFill": "#FFFFFF",
                    "thumbDisabledBorder": "#1C1D2115"
                },
                "dialog": {
                    "fill": "#FFFFFF",
                    "headerDivider": "#F6F6F6",
                    "closeIconFill": "#1C1D219A",
                    "titleText": "#1C1D21",
                    "bodyText": "#1C1D21CC"
                },
                "cngCheckbox": {
                    "normalFill": "#F0F0F3",
                    "normalBorder": "#5f5753",
                    "normalDisabledFill": "#5f575367",
                    "normalDisabledBorder": "#5f57534D",
                    "hoverFill": "#EEEEEE",
                    "hoverBorder": "transparent",
                    "hoverRippleFill": "#0a808010",
                    "normalHoverRipple": "#1C1D2105",
                    "activeHoverRipple": "#0a80801a",
                    "activeFill": "#00857D",
                    "activeDisabledFill": "#0a808080",
                    "forLabelText": "#5F5753",
                    "indeterminateIcon": "#0a8080"
                },
                "slider": {
                    "railNormalFill": "rgba(94, 129, 244, 0.235)",
                    "trackNormalFill": "#00857D",
                    "thumbNormalFill": "#7CE7AC",
                    "thumbBorder": "#FFFFFF",
                    "railDisabledFill": "#EEEEEE2E",
                    "trackDisabledFill": "#E7E9F280",
                    "thumbDisabledFill": "#D6D6D6",
                    "thumbDisabledBorder": "#E7E9F2",
                    "hoverRippleFill": "#3300001F",
                    "hoverRippleBorder": "#3300003E",
                    "mark": "rgba(118, 128, 163, 0.501)",
                    "markActive": "#FFFFFF9F",
                    "valueFill": "#EEEEEE",
                    "valueText": "#252631"
                },
                "linearProgress": { "railFill": "#F6F6F6", "progressBarFill": "#7CE7AC" },
                "textFormControl": {
                    "normalFill": "transparent",
                    "normalBorder": "#5f5753",
                    "normalArrowFill": "#330000",
                    "placeholderText": "rgba(0, 0, 0, 0.64)",
                    "placeholderTextFilled": "#7680a3",
                    "placeholderTextFocused": "#000",
                    "placeholderTextFocusedHover": "#1c1d21cb",
                    "placeholderTextError": "#DF2901",
                    "placeholderTextDisabled": "rgba(0, 0, 0, 0.25)",
                    "valueText": "#1C1D21",
                    "helperText": "rgba(0, 0, 0, 0.9)",
                    "helperTextError": "#DF2901",
                    "hoverFill": "#f2f1ef",
                    "hoverBorder": "#5f5753",
                    "activeFill": "#f2f1ef",
                    "activeBorder": "#121212",
                    "activeArrowFill": "#00857D",
                    "dropdownFill": "#fafbfc",
                    "dropdownMenuHoverFill": "#33000019",
                    "dropdownMenuSelectedFill": "#1c1d210f",
                    "calendarControlFill": "#ffffff",
                    "calendarControlNavBtnNormalFill": "#F5F5FA",
                    "calendarControlNavBtnHoverFill": "#dedee4",
                    "calendarControlNavBtnIconFill": "#7680a3",
                    "calendarControlDayLabel": "#1C1D21",
                    "groupSelectNormalFill": "#efefef",
                    "groupSelectHoverFill": "rgba(94, 129, 244, 0.08)",
                    "groupSelectActiveFill": "rgba(94, 129, 244, 0.08)",
                    "disabledBorder": "rgba(117, 117, 117, 0.2)",
                    "disabledFill": "transparent",
                    "overallDisabledFieldOpacity": 1
                },
                "chips": {
                    "normalFill": "rgba(94, 129, 244, 0.1)",
                    "valueText": "#1C1D21CC",
                    "clickableHoverFill": "#1C1D210F",
                    "clickableFocusedFill": "#1C1D2133",
                    "clickableValueText": "#00857D",
                    "avatarFill": "#FFFFFF",
                    "avatarBorder": "#e2e1e1",
                    "avatarText": "#1C1D21CC",
                    "avatarIconFill": "#1C1D21CC",
                    "actionBtnNormalFill": "transparent",
                    "actionBtnHoverFill": "#ffffff",
                    "actionBtnIconFill": "#1C1D219A",
                    "disabledFill": "#F6F6F6",
                    "disabledText": "#1c1d2154",
                    "outlineChipFill": "transparent",
                    "outlineChipBorder": "rgba(94, 129, 244, 0.6)",
                    "outlineChipDisabledFill": "transparent",
                    "outlineChipDisabledBorder": "#1C1D2133",
                    "outlineClickableHoverFill": "#F5F5FA",
                    "outlineClickableFousedFill": "#F0F0F3"
                },
                "cngMuiTable": {
                    "tableFill": "transparent",
                    "tableBorder": "#E4EBF6A3",
                    "tableShadow": "#00000008",
                    "toolbarFill": "transparent",
                    "toolbarTitle": "#1C1D21",
                    "toolbarSubTitle": "#7680a3",
                    "toolbarIconFill": "#7680a3",
                    "toolbarHighlightFill": "#ffffff",
                    "toolbarHighlightText": "#7680a3",
                    "tableHeaderFill": "#F5F5FA65",
                    "tableHeaderCellFill": "#F5F5FA65",
                    "tableHeaderProgressRail": "#ECECF2",
                    "tableHeaderProgressTrack": "#00857D",
                    "tableHeaderCellText": "#1C1D21",
                    "tableBodyRowCellText": "#1C1D21CC",
                    "tableBodyRowFill": "#FFFFFF",
                    "tableBodyRowBorder": "#E4EBF6A2",
                    "tableBodyRowCellBorder": "#E4EBF6A2",
                    "tableBodyHighlightedRowFill": "rgba(118, 128, 163, 0.058)",
                    "tableBodySelectedRowFill": "rgba(94, 129, 244, 0.098)",
                    "tableBodySelectedRowBorder": "#E4EBF6A2",
                    "tableBodySelectedRowCellBorder": "#E4EBF6A2",
                    "tablePageBtnHoverFill": "#ececec",
                    "tablePageBtnDisabledFill": "#f5f5fa80"
                },
                "functionButtons": {
                    "normalFill": "#F5F5FA",
                    "normalIconFill": "#7680a3",
                    "hoverFill": "#33000019",
                    "focusFill": "#33000019",
                    "activeFill": "#33000019",
                    "activeIconFill": "#7680a3"
                },
                "popoverMenu": {
                    "paperFill": "#FFFFFF",
                    "listItemNormalFill": "#FFFFFF",
                    "listItemHoverFill": "rgba(118, 128, 163, 0.062)",
                    "listItemText": "#1C1D21",
                    "listItemIconFill": "#1C1D21",
                    "listItemActiveFill": "rgba(118, 128, 163, 0.062)"
                },
                "innerPage": {
                    "pageFill": "#FFFFFF",
                    "primaryTitle": "#1C1D21",
                    "secondaryTitle": "#1C1D21",
                    "paragraph": "#1C1D21CC",
                    "linkTextBlue": "#00857D",
                    "linkTextWhite": "#FFFFFF",
                    "iconFill": "#FFFFFF"
                },
                "numericStepper": {
                    "normalLabel": "#1C1D2133",
                    "activeLabel": "#00857D",
                    "normalFill": "transparent",
                    "normalBorder": "#EEEEEE",
                    "hoverBorder": "#EEEEEE",
                    "valueText": "#1C1D21"
                },
                "cngButton": {
                    "contrastText": "#ffffff",
                    "outline": {
                        "normalFill": "#FFFFFF",
                        "border": "#00857D",
                        "positiveFaceColor": "#00857D",
                        "negativeFaceColor": "#DF2901",
                        "disabledFaceColor": "#1C1D2133",
                        "hoverFill": "#E7F2F2",
                        "activeFill": "#E7F2F2",
                        "disabledFill": "transparent"
                    },
                    "function": {
                        "primaryNormalFill": "#00857D",
                        "primaryHoverFill": "#00716a",
                        "primaryActiveFill": "#00716a",
                        "primaryDisabledFill": "#33000033",
                        "primaryFaceColor": "#FAFBFF",
                        "secondaryNormalFill": "#330000",
                        "secondaryHoverFill": "#5a0000",
                        "secondaryActiveFill": "#5a0000",
                        "secondaryDisabledFill": "#00857D33",
                        "secondaryFaceColor": "#7680a3",
                        "outlineNormalFill": "#ffffff",
                        "outlineBorder": "#ECECF2",
                        "outlineFaceColor": "#7680a3",
                        "disabledFaceColor": "#1C1D2133",
                        "outlineHoverFill": "#E7F2F2",
                        "outlineActiveFill": "#E7F2F2",
                        "outlineDisabledFill": "transparent"
                    },
                    "text": {
                        "contrastText": "#7680a3",
                        "hoverFill": "rgba(118, 128, 163, 0.058)",
                        "activeFill": "rgba(94, 129, 244, 0.3)"
                    }
                },
                "appBar": { "contrastText": "#263238" },
                "breadcrumbGoBackBtn": { "normalFill": "#ececec", "hoverFill": "#d3d3d3" },
                "signatureCanvas": {
                    "penColor": "black",
                    "normalFill": "#fcfdff",
                    "errorFill": "#fefcfd",
                    "normalBorder": "#00857D",
                    "errorBorder": "#DF2901",
                    "errorText": "#DF2901"
                }
            }
        },
        "props": {
            "MuiButton": { "disableRipple": true },
            "MuiCheckbox": {
                "color": "secondary",
                "disableFocusRipple": true,
                "disableRipple": true
            }
        },
        "shadows": [
            "none",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 1px 2px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 2px 2px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 3px 4px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 3px 4px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 4px 6px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 4px 6px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 4px 8px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 5px 8px -2px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 6px 12px -4px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 7px 12px -4px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 6px 16px -4px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 7px 16px -4px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 8px 18px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 9px 18px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 10px 20px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 11px 20px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 12px 22px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 13px 22px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 14px 24px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 16px 28px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 18px 30px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 20px 32px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 22px 34px -8px rgba(0,0,0,0.1)",
            "0 0 1px 0 rgba(0,0,0,0.08), 0 24px 36px -8px rgba(0,0,0,0.1)"
        ]
    },
    {
        "name": "ONE_DARK",
        "typography": {
            "fontFamily": "Roboto,Tahoma,Helvetica,Arial,sans-serif,-apple-system,BlinkMacSystemFont,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""
        },
        "overrides": {
            "MuiChip": {
                "root": {
                    "backgroundColor": "#FFFFFFA1",
                    "color": "#121212"
                }
            },
            "MuiDrawer": {
                "paperAnchorDockedLeft": {
                    "borderRight": 0
                }
            },
            "MuiLink": { "root": { "color": "rgba(255, 255, 255, 0.7) !important" } },
            "MuiTableCell": {
                "head": {
                    "& .ng-form-control.no-label": {
                        "& > input[type=\"text\"].MuiFilledInput-input": {
                            "padding": "8px 4px !important",
                            "fontSize": ".9rem"
                        },
                        "& > .MuiInputAdornment-root": {
                            "&.MuiInputAdornment-positionEnd": { "marginLeft": "0" },
                            "& > .MuiIconButton-root": {
                                "padding": "8px",
                                "& svg": {
                                    "width": ".8em",
                                    "height": ".8em"
                                }
                            }
                        }
                    },
                    ".MuiTableRow-head:not(.table-filter-row) > &": {
                        "padding": "10px 16px !important"
                    },
                    ".table-filter-row &": {
                        "padding": "9.6px",
                        "& .MuiGrid-container": {
                            "flexWrap": "nowrap"
                        }
                    }
                }
            }
        },
        "palette": {
            "mode": "dark",
            "action": {
                "active": "rgba(255, 255, 255, 0.54)",
                "hover": "rgba(255, 255, 255, 0.04)",
                "selected": "rgba(255, 255, 255, 0.08)",
                "disabled": "rgba(255, 255, 255, 0.26)",
                "disabledBackground": "rgba(255, 255, 255, 0.12)",
                "focus": "rgba(255, 255, 255, 0.12)"
            },
            "primary": {
                "main": "#00857D",
                "light": "#fff04e",
                "dark": "#00716a",
                "contrastText": "#ffffff"
            },
            "secondary": {
                "main": "#330000",
                "light": "#eea75b",
                "dark": "#5a0000",
                "contrastText": "#ffffff"
            },
            "success": {
                "main": "#29A855",
                "light": "#2CB55C",
                "dark": "#10602C",
                "contrastText": "#ffffff"
            },
            "error": {
                "main": "#DF2901",
                "light": "#991C00",
                "dark": "#E9694D",
                "contrastText": "#ffffff"
            },
            "warning": {
                "main": "#FBD300",
                "light": "#DFFC30",
                "dark": "#615100",
                "contrastText": "#121212"
            },
            "background": {
                "default": "#000",
                "othersTopbar": "#282C34",
                "sectionOddBg": "#282C34",
                "sectionAltBg": "#1c1f25",
                "dark": "#1c2025",
                "paper": "#000",
                "stepperActiveFill": "#00857D",
                "stepperNormalText": "#ffffff",
                "stepperActiveText": "#ffffff",
                "stepperCompletedText": "#ffffff",
                "success": "#29A855",
                "error": "#DF2901",
                "white": "#ffffff"
            },
            "text": {
                "primary": "#ffffff",
                "secondary": "rgba(255, 255, 255, 0.7)",
                "disabled": "rgba(255, 255, 255, 0.5)",
                "hint": "rgba(255, 255, 255, 0.5)",
                "icon": "rgba(255, 255, 255, 0.5)",
                "navSecondary": "rgba(255, 255, 255, 0.7)",
                "navActive": "#ffffff",
                "textSecondary": "#8996AF",
                "textActive": "#1C1D21",
                "error": "#FF808B",
                "success": "#29A855",
                "gray900": "#1C1D21CC",
                "tooltip": "#1C1D21",
                "cngBlack": "#1C1D21",
                "widgetTitle": "#e6e5e8",
                "widgetSubTitle": "#adb0bb",
                "base": "#adb0bb"
            },
            "color": {
                "primaryColor": "#00857D",
                "primaryDark": "#00716a",
                "primaryGray": "#7680a3",
                "secondaryGreen": "#29A855",
                "secondaryRed": "#ff808b",
                "primaryAzure": "#3300001a",
                "white": "#ffffff"
            },
            "component": {
                "dropzone": {
                    "normalFill": "#2B2C33",
                    "normalBorder": "#E7E9F23C",
                    "hoverborder": "#5e81f4",
                    "hoverFill": "#5E81F419",
                    "activeFill": "#5475e019"
                },
                "chart": {
                    "gridLines": "#6f6b6b",
                    "zeroLine": "#989696",
                    "polarCircleBorder": "#6f6b6b",
                    "radarCircleBorder": "#6f6b6b",
                    "legend": "#8996AF",
                    "label": "#6f6b6b"
                },
                "topBarMegaMenu": {
                    "backgroundFill": "rgba(40, 44, 52, 1)",
                    "gradientStartFill": "rgba(40, 44, 52, 0.5)",
                    "gradientEndFill": "rgba(0, 0, 0, 18%)",
                    "degree": "150deg",
                    "scrollbarFill": "rgba(0, 0, 0, 18%)",
                    "scrollbarThumbFill": "#2d6bc2",
                    "scrollbarTrackFill": "#323c58",
                    "lineGradientStartFill": "rgba(40, 44, 52, 0.1)",
                    "lineGradientMidFill": "rgba(0, 0, 0, 0.5)",
                    "lineGradientEndFill": "rgba(40, 44, 52, 0.1)"
                },
                "paper": { "borderColor": "#E7E9F221", "fill": "#2B2C33" },
                "toggleButton": {
                    "normalFill": "#1C1D2180",
                    "hoverFill": "#E7E9F23C",
                    "activeFill": "#E7E9F23C",
                    "activeBorder": "#5E81F4",
                    "normalText": "#8996AF",
                    "activeText": "#FFFFFF"
                },
                "stepper": {
                    "normalFill": "#FFFFFF6C",
                    "activeFill": "#5E81F4",
                    "completedFill": "#5E81F4",
                    "normalText": "#E7E9F2",
                    "activeText": "#FFFFFF",
                    "completedText": "#E7E9F2",
                    "iconFill": "#FFFFFF"
                },
                "pagination": {
                    "btnNormalText": "#E7E9F2",
                    "btnHoverFill": "#E7E9F23C",
                    "btnActiveFill": "#00857D",
                    "btnActiveText": "#FFFFFF",
                    "btnNavNormalFill": "#E7E9F23C",
                    "btnNavNormalText": "#E7E9F2",
                    "btnNavHoverFill": "#ACAEB73c"
                },
                "dropdown": {
                    "normalFill": "transparent",
                    "normalBorder": "#E7E9F23C",
                    "normalArrowFill": "#E7E9F2",
                    "placeholderText": "#ffffff80",
                    "valueText": "#E7E9F2",
                    "hoverFill": "#3300000A",
                    "activeFill": "#3300000A",
                    "activeBorder": "#00857D",
                    "activeArrowFill": "#5E81F4"
                },
                "hamburger": { "stateClose": "#8996AF", "stateOpen": "#FFFFFF" },
                "snackBar": {
                    "displayText": "#1C1D21",
                    "iconFill": "#1C1D21",
                    "success": "#29A855",
                    "error": "#ff808b",
                    "info": "#8996af",
                    "warning": "#f4be5e",
                    "actionIconFill": "#1C1D219A"
                },
                "bannerAlert": {
                    "displayText": "#E7E9F2",
                    "actionTextLink": "#5E81F4",
                    "actionIconFill": "#FFFFFF9A",
                    "bannerFill": "#0C0C0D"
                },
                "checkbox": {
                    "hoverFill": "#E2E2E2",
                    "unCheckHoverFill": "#E7E9F21F",
                    "unCheckHoverBorder": "#E7E9F24D",
                    "checkHoverFill": "#5E81F41F",
                    "checkHoverBorder": "#5E81F44D"
                },
                "switch": {
                    "trackNormalFill": "#282C34",
                    "trackNormalDisabledFill": "#282C3465",
                    "trackNormalDisabledBorder": "#282C344D",
                    "trackActiveFill": "#0a8080",
                    "trackActiveDisabledFill": "#0a808080",
                    "thumbFill": "#FFFFFF",
                    "thumbBorder": "#1C1D2133",
                    "thumbDisabledFill": "#FFFFFF",
                    "thumbDisabledBorder": "#1C1D2115"
                },
                "dialog": {
                    "fill": "#2B2C33",
                    "headerDivider": "#E7E9F214",
                    "closeIconFill": "#FFFFFF9A",
                    "titleText": "#FFFFFF",
                    "bodyText": "#E7E9F2"
                },
                "cngCheckbox": {
                    "normalFill": "#0a8080A2",
                    "normalBorder": "#FFFFFF0F",
                    "normalDisabledFill": "#0a808029",
                    "normalDisabledBorder": "#FFFFFF05",
                    "hoverFill": "#0a8080B7",
                    "hoverBorder": "#FFFFFF10",
                    "hoverRippleFill": "#0a808010",
                    "normalHoverRipple": "#0a808010",
                    "activeHoverRipple": "#0a80801A",
                    "activeFill": "#0a8080",
                    "activeDisabledFill": "#0a808080",
                    "forLabelText": "#8996AF",
                    "indeterminateIcon": "#FFFFFF"
                },
                "slider": {
                    "railNormalFill": "#3300003C",
                    "trackNormalFill": "#00857D",
                    "thumbNormalFill": "#330000",
                    "thumbBorder": "#FFFFFF",
                    "railDisabledFill": "#EEEEEE2E",
                    "trackDisabledFill": "#E7E9F280",
                    "thumbDisabledFill": "#8E8E8E",
                    "thumbDisabledBorder": "#E7E9F2",
                    "hoverRippleFill": "#5E81F41F",
                    "hoverRippleBorder": "#5E81F43E",
                    "mark": "#5a000080",
                    "markActive": "#FFFFFF9F",
                    "valueFill": "#E7E9F229",
                    "valueText": "#E7E9F2"
                },
                "linearProgress": { "railFill": "#EEEEEE2E", "progressBarFill": "#7CE7AC" },
                "textFormControl": {
                    "normalFill": "transparent",
                    "normalBorder": "#5f5753",
                    "normalArrowFill": "#E7E9F2",
                    "placeholderText": "#FFFFFF99",
                    "placeholderTextFilled": "#fff04e",
                    "placeholderTextFocused": "#ffffff",
                    "placeholderTextFocusedHover": "#1c1d21cb",
                    "placeholderTextError": "#FF808B",
                    "placeholderTextDisabled": "#ffffff26",
                    "valueText": "#E7E9F2",
                    "helperText": "#ffffffe6",
                    "helperTextError": "#FF808B",
                    "hoverFill": "#3300003C",
                    "hoverBorder": "#eeeeee61",
                    "activeFill": "#3300003C",
                    "activeBorder": "#00857D",
                    "activeArrowFill": "#00857D",
                    "dropdownFill": "transparent",
                    "dropdownMenuHoverFill": "#5e81f419",
                    "dropdownMenuSelectedFill": "#1c1d210f",
                    "calendarControlFill": "#2B2C33",
                    "calendarControlNavBtnNormalFill": "#E7E9F23C",
                    "calendarControlNavBtnHoverFill": "#c6c8d03c",
                    "calendarControlNavBtnIconFill": "#00716a",
                    "calendarControlDayLabel": "#E7E9F2",
                    "groupSelectNormalFill": "#23252a",
                    "groupSelectHoverFill": "#33353a",
                    "groupSelectActiveFill": "#2e3035",
                    "disabledBorder": "#EEEEEE",
                    "disabledFill": "transparent",
                    "overallDisabledFieldOpacity": 0.5
                },
                "chips": {
                    "normalFill": "#FFFFFFA1",
                    "valueText": "#E7E9F2",
                    "clickableHoverFill": "#5E81F42E",
                    "clickableFocusedFill": "#5E81F41A",
                    "clickableValueText": "#5E81F4",
                    "avatarFill": "#E7E9F215",
                    "avatarBorder": "#E7E9F215",
                    "avatarText": "#E7E9F2",
                    "avatarIconFill": "#E7E9F2",
                    "actionBtnNormalFill": "transparent",
                    "actionBtnHoverFill": "#ffffff82",
                    "actionBtnIconFill": "#FFFFFF98",
                    "disabledFill": "#E7E9F215",
                    "disabledText": "#E7E9F23C",
                    "outlineChipFill": "transparent",
                    "outlineChipBorder": "#33000099",
                    "outlineChipDisabledFill": "transparent",
                    "outlineChipDisabledBorder": "#E7E9F229",
                    "outlineClickableHoverFill": "#E7E9F20F",
                    "outlineClickableFousedFill": "#E7E9F219"
                },
                "cngMuiTable": {
                    "tableFill": "#2B2C33",
                    "tableBorder": "#46484D",
                    "tableShadow": "#0000003D",
                    "toolbarFill": "transparent",
                    "toolbarTitle": "#FFFFFF",
                    "toolbarSubTitle": "#E7E9F2",
                    "toolbarIconFill": "#00716a",
                    "toolbarHighlightFill": "#1b1d1f",
                    "toolbarHighlightText": "#00716a",
                    "tableHeaderFill": "#E7E9F215",
                    "tableHeaderCellFill": "#E7E9F215",
                    "tableHeaderProgressRail": "#0C0C0D",
                    "tableHeaderProgressTrack": "#5E81F4",
                    "tableHeaderCellText": "#E7E9F2",
                    "tableBodyRowCellText": "#E7E9F2",
                    "tableBodyRowFill": "#2B2C33",
                    "tableBodyRowBorder": "#46484DA2",
                    "tableBodyRowCellBorder": "#46484DA2",
                    "tableBodyHighlightedRowFill": "#3300001A",
                    "tableBodySelectedRowFill": "#3300001A",
                    "tableBodySelectedRowBorder": "#46484DA2",
                    "tableBodySelectedRowCellBorder": "#46484DA2",
                    "tablePageBtnHoverFill": "#ececec",
                    "tablePageBtnDisabledFill": "#f5f5fa80"
                },
                "functionButtons": {
                    "normalFill": "#E7E9F23C",
                    "normalIconFill": "#00716a",
                    "hoverFill": "#5E81F414",
                    "focusFill": "#5E81F414",
                    "activeFill": "#5E81F414",
                    "activeIconFill": "#5E81F4"
                },
                "popoverMenu": {
                    "paperFill": "#23252a",
                    "listItemNormalFill": "#23252a",
                    "listItemHoverFill": "#FFFFFF10",
                    "listItemText": "#E7E9F2",
                    "listItemIconFill": "#E7E9F2",
                    "listItemActiveFill": "#E7E9F229"
                },
                "innerPage": {
                    "pageFill": "#2B2C33",
                    "primaryTitle": "#FFFFFF",
                    "secondaryTitle": "#FFFFFF",
                    "paragraph": "#E7E9F2",
                    "linkTextBlue": "#5E81F4",
                    "linkTextWhite": "#FFFFFF",
                    "iconFill": "#FFFFFF"
                },
                "numericStepper": {
                    "normalLabel": "#1C1D2133",
                    "activeLabel": "#5E81F4",
                    "normalFill": "transparent",
                    "normalBorder": "#EEEEEE",
                    "hoverBorder": "#E7E9F23C",
                    "valueText": "#E7E9F2"
                },
                "cngButton": {
                    "contrastText": "#FFFFFF",
                    "outline": {
                        "normalFill": "transparent",
                        "border": "#00857D",
                        "positiveFaceColor": "#00857D",
                        "negativeFaceColor": "#DF2901",
                        "disabledFaceColor": "#ffffff42",
                        "hoverFill": "#E7F2F2",
                        "activeFill": "#E7F2F2",
                        "disabledFill": "transparent"
                    },
                    "function": {
                        "primaryNormalFill": "#00857D",
                        "primaryHoverFill": "#00716a",
                        "primaryActiveFill": "#00716a",
                        "primaryDisabledFill": "transparent",
                        "primaryFaceColor": "#ffffff",
                        "secondaryNormalFill": "#330000",
                        "secondaryHoverFill": "#5a0000",
                        "secondaryActiveFill": "#5a0000",
                        "secondaryDisabledFill": "transparent",
                        "secondaryFaceColor": "#000000",
                        "outlineNormalFill": "transparent",
                        "outlineBorder": "#E7E9F23C",
                        "outlineFaceColor": "#00857D",
                        "disabledFaceColor": "#ffffff42",
                        "outlineHoverFill": "#3300001A",
                        "outlineActiveFill": "#3300001A",
                        "outlineDisabledFill": "transparent"
                    },
                    "text": { "contrastText": "#fff04e", "hoverFill": "#FFFFFF", "activeFill": "#fff04e" }
                },
                "appBar": { "contrastText": "#FFFFFF" },
                "breadcrumbGoBackBtn": { "normalFill": "#ececec1a", "hoverFill": "#d3d3d333" },
                "signatureCanvas": {
                    "penColor": "white",
                    "normalFill": "#282C34",
                    "errorFill": "#282C34",
                    "normalBorder": "#5e81f4",
                    "errorBorder": "#FF808B",
                    "errorText": "#FF808B"
                }
            }
        },
        "props": {
            "MuiButton": { "disableRipple": true },
            "MuiCheckbox": {
                "color": "primary",
                "disableFocusRipple": true,
                "disableRipple": true
            }
        },
        "shadows": [
            "none",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 2px 2px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 4px 8px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 6px 12px -4px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 7px 12px -4px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 6px 16px -4px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 7px 16px -4px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 8px 18px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 9px 18px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 10px 20px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 11px 20px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 12px 22px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 13px 22px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 14px 24px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 16px 28px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 18px 30px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 20px 32px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 22px 34px -8px rgba(0,0,0,0.50)",
            "0 0 1px 0 rgba(0,0,0,0.70), 0 24px 36px -8px rgba(0,0,0,0.50)"
        ]
    }
]


export default defaultTheme;