import { useHistory } from 'react-router-dom';
import {useServices,constants} from "cng-web-lib";
import React, { useEffect, useState } from "react";
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'

/**
 * Method to return the style based on the Status Type
 * @param {*} status 
 */

function StatusStyle (status){
    switch (status) {
        case 0:
        case "0":
        case 1:
        case "1":
          return { background: "lightgrey", color: "white", textAlign: "center" }
          break;
        case 17:
        case "17":
          return { background: "lightgreen", color: "white", textAlign: "center" }
          break;   
        case 200:
        case "200":
          return { background: "lightgreen", color: "white", textAlign: "center" }
          break;
        case 25:
        case "25":
          return { background: "#8B8000", color: "white", textAlign: "center" }
          break; 
        case 20:
        case "20":
          return { background: "indianred", color: "white", textAlign: "center"}
          break;
        case 21:
        case "21":
          return { background: "indianred", color: "white", textAlign: "center"}
          break;
        case 22:
        case "22":
          return { background: "indianred", color: "white", textAlign: "center"}
          break; 
        case 28:
        case "28":
          return { background: "indianred", color: "white", textAlign: "center"}
          break; 
        case 29:
        case "29":
          return { background: "indianred", color: "white", textAlign: "center"}
          break; 
        case 32:
        case "32":    
          return { background: "green", color: "white", textAlign: "center"}
          break;
        case 33:
        case "33":    
          return { background: "green", color: "white", textAlign: "center"}
          break;    
        case 1048:
        case "1048":    
          return { background: "lightgreen", color: "white", textAlign: "center"}
          break;
        case 1049:
        case "1049":
          return { background: "indianred", color: "white", textAlign: "center"}
          break;
        case 1002:
        case "1002":    
          return { background: "mediumaquamarine", color: "white", textAlign: "center"}
          break;
        case 1003:
        case "1003":    
          return { background: "green", color: "white", textAlign: "center"}
          break;
        case 1004:
        case "1004":    
          return { background: "indianred", color: "white", textAlign: "center"}
          break;
        case 1000:
        case "1000":    
          return { background: "green", color: "white", textAlign: "center"}
          break;
        case 1001:
        case "1001":
          return { background: "indianred", color: "white", textAlign: "center"}
          break;
        case 1005:  
        case "1005":
          return { background: "lightblue", color: "white", textAlign: "center"}
          break;
        case 1008: 
        case "1008":
          return { background: "lightgrey", color: "white", textAlign: "center"}
          break;   
        default:
          return { background: "yellow", color: "black", textAlign: "center"}
          break;                
      } 
}

function ACEStatusStyle (rowData, manPort){
  switch (rowData.status) {
      case 1000:
      case "1000":
        if(rowData.eventLocation && manPort && rowData.shipmentType === 'REGULAR' && rowData.eventLocation !== manPort){
          return { background: "indianred", color: "white", textAlign: "center"}
          break;
        }
        return { background: "green", color: "white", textAlign: "center"}
          break;
      case 1001:
      case "1001":
        return { background: "indianred", color: "white", textAlign: "center"}
        break;
      case 1002:
      case "1002":    
        return { background: "mediumaquamarine", color: "white", textAlign: "center"}
        break;
      case 1003:
      case "1003":    
        return { background: "green", color: "white", textAlign: "center"}
        break;
      case 1004:
      case "1004":    
        return { background: "indianred", color: "white", textAlign: "center"}
        break;
      
      case 1005:  
      case "1005":
        return { background: "lightblue", color: "white", textAlign: "center"}
        break;
      case 1007:
        case "1007":
          return { background: "indianred", color: "white", textAlign: "center"}
          break;
      case 1008: 
      case "1008":
        return { background: "lightgrey", color: "white", textAlign: "center"}
        break;   
      default:
        return { background: "yellow", color: "white", textAlign: "center"}
        break;                
    } 
}

/**
 * Method to Submit Multiple messages to Queue
 * @param {*} url 
 * @param {*} rowData 
 * @param {*} showNotification 
 * @param {*} cngTableRef 
 */
function SubmitAll (url, rowData, showNotification, cngTableRef, successMsg, errorMsg,securedSendRequest){
  
  function onSuccess(response) {

    if (response.status == 200) {
      const data= response.data
      var errorMessage = null;
      for (var key in data) {
        var obj = data[key];
        if( obj.errorMessages!=null && obj.errorMessages.length>0){
          errorMessage = obj.errorMessages;
          showNotification("error", errorMessage)
        }
          
      }

      if(errorMessage==null || errorMessage.length == 0)
      {
        showNotification("success", successMsg)

        if (cngTableRef.current.performRefresh) {
          cngTableRef.current.performRefresh()
        }
      }
    } else {
      showNotification("error", errorMsg)
    }
  }

  function onError(error) {
    showNotification("error", errorMsg)
  }

  function onComplete(response){

  }
  const config = {}

  securedSendRequest.execute('POST',url, rowData, onSuccess, onError, onComplete,config,null);
}

function SubmitAndRedirect (url, destUrl,rowData, showNotification, successMsg, setIsLoading, history, errorMsg,securedSendRequest){
  setIsLoading(true)
  
  function onSuccess(response) {
    const data=response.data
    var errorMessage = null;
    var errorFlag = false;
    for (var key in data) {
      var obj = data[key];
      if( obj.errorMessages!=null && obj.errorMessages.length>0){
        errorMessage = obj.errorMessages;
        showNotification("error", errorMessage)
        errorFlag = true;
      }
    }
    if(!errorFlag){
      showNotification("success", successMsg)
      setIsLoading(false)
      history.push(destUrl)
    }
     setIsLoading(false)
  }

  function onError(error) {
  }

  function onComplete(response){

  }
  const config = {}

  securedSendRequest.execute('POST',url, rowData, onSuccess, onError, onComplete,config,null);

}

function SubmitMultiplefromManifest (url, rowData, showNotification, successMsg,setIsLoading,history,errorMsg,securedSendRequest){

  function onSuccess(response) {

    const data=response.data
    if(data.errorMessages!=null && data.errorMessages.length>0)
    {
      showNotification("error", data.errorMessages)
    }
    else if(data.errorMessages===null || data.errorMessages===undefined)
    {
      showNotification("success", successMsg)
      setIsLoading(false)
      history.push("/aci-highway/manifest")
    }
    else{
      showNotification("error", errorMsg)
    }
     setIsLoading(false)
  }

  function onError(error) {
  }

  function onComplete(response){
  }
  const config = {}

  securedSendRequest.execute('POST',url, rowData, onSuccess, onError, onComplete,config,null);
}

function AttachAll (url, rowData, showNotification, successMsg,history,goBackUrl,securedSendRequest){

  function onSuccess(response) {

    const data=response.data
    if(data.errorMessages!=null && data.errorMessages!==undefined &&data.errorMessages.length>0 )
    {
      showNotification("error", data.errorMessages)
    }
    else if(data.errorMessages===null || data.errorMessages===undefined)
    {
      showNotification("success", successMsg)
      history.push(goBackUrl);
    }
  }

  function onError(error) {
    console.log(error);
  }

  function onComplete(response){

  }
  const config = {}

  securedSendRequest.execute('POST',url, rowData, onSuccess, onError, onComplete,config,null);
    
}

/**
 * Method to Submit Multiple messages to Queue
 * @param {*} url 
 * @param {*} rowData 
 * @param {*} showNotification 
 * @param {*} cngTableRef 
 */
function DettachAll (url, rowData, showNotification, successMsg,setIsLoading,securedSendRequest){

  function onSuccess(response) {

   const data=response.data;
   if(data.errorMessages!=null && data.errorMessages.length>0)
   {
     showNotification("error", data.errorMessages)
   }
   else if(data.errorMessages===null || data.errorMessages===undefined)
   {
     showNotification("success", successMsg)
     console.log(rowData.manifestId)
   }
   setIsLoading(false)
  }

  function onError(error) {
    console.log(error);
  }

  function onComplete(response){

  }
  const config = {}

  securedSendRequest.execute('POST',url, rowData, onSuccess, onError, onComplete,config,null);
   
}

/**
 * Method to disable Submit Button based on Status
 * @param {*} rowData 
 */
function DisableSubmitButton(rowData){
    var isDisable = false
    for(let record in rowData){
      var row = rowData[record]
      if(row.hdrStatus === 1048 || row.hdrStatus === 1 || row.a6aStatus === "1048" || row.a6aStatus === "1"){
        isDisable = true
        break
      }
    }
    return isDisable
}


/**
 * Method to disable Aci Highway Submit Button based on Status
 * @param {*} rowData 
 */
 function DisableHighwaySubmitButton(rowData){
  var isDisable = false
  for(let record in rowData){
    var row = rowData[record]
    if(row.status === "1008"){
      isDisable = true
      break
    }
  }
  return isDisable
}

/**
 * Method to disable Dettach Button based on Status
 * @param {*} rowData 
 */
 function DisableDettachButton(rowData){
  var isDisable = false
  for(let record in rowData){
    var row = rowData[record]
    if(row.status === "1000" || row.status === "1008" || row.status==="1007" || row.status==="1004"){
      isDisable = true
      break
    }
  }
  return isDisable
}

/**
 * Method to disable Attach Button based on Status
 * @param {*} rowData 
 */
 function DisableAttachButton(rowData){
  var isDisable = false
  for(let record in rowData){
    var row = rowData[record]
    if(row.status === "1008" || row.status === "1006" || row.status ==="1002"){
      isDisable = true
      break
    }
  }
  return isDisable
}

/**
 * Method to disable Submit Button based on Status
 * @param {*} rowData 
 */
function DisableInvoiceSubmitButton(rowData){
  var isDisable = false
  for(let record in rowData){
    var row = rowData[record]
    if(row.status === 'ST'){
      isDisable = true
      break
    }
  }
  return isDisable
}

/**
 * Method to disable Delete Button based on Status
 * @param {*} rowData 
 */
function DisableDeleteButton(rowData){
  var isDisable = false
  for(let record in rowData){
    var row = rowData[record]
    if(row.hdrStatus !== 1005){
      isDisable = true
      break
    }
  }
  return isDisable
}

function DisableDeleteButtonForHighway(rowData){
  var isDisable = false
  for(let record in rowData){
    var row = rowData[record]
    if(row.status !== "1005"){
      isDisable = true
      break
    }
  }
  return isDisable
}

function getStatusMetadataMini(status) {

  switch (status) {
    case 1000: case '1000':
      return { label: 'Accepted', color: '#29A855', contrastColor: '#ffffff' }
    case 1001: case '1001':
      return { label: 'Rejected', color: '#DF2901', contrastColor: '#ffffff' }  
    case 1003: case '1003':  
      return { label: 'Cancellation Accepted', color: '#FFC400', contrastColor: '#121212' }
    case 1004: case '1004': 
      return { label: 'Cancellation Rejected', color: '#DF2901', contrastColor: '#ffffff' }  
    case 1005: case '1005': case 'DR':
      return { label: 'Draft', color: '#0662BB', contrastColor: '#ffffff' }
    case 1006: case '1006':
      return { label: 'Status Notification', color: '#d3d3d3', contrastColor: '#121212' }    
    case 1007: case '1007':   
      return { label: 'Replace Rejected', color: '#DF2901', contrastColor: '#ffffff' }
    case 1008: case '1008':
      return { label: 'Sent', color: '#ffc400', contrastColor: '#121212' }
    case 1009: case '1009':
      return { label: 'Modification Rejected', color: '#DF2901', contrastColor: '#ffffff' }   
    default:
      return { label: status, color: '#121212', contrastColor: '#ffffff' }  
  } 
}

function getStatusMetadata(status) {
  switch (status) {
    case 1: case '1':
      return { label: 'CBSA Received and Processing', color: '#ffc400', contrastColor: '#121212' }
    case 14: case '14':
      return { label: 'Reject Type', color: '#DF2901', contrastColor: '#ffffff' }
    case 17: case '17':
      return { label: 'Functional Acnkowledgement Message Received', color: '#29A855', contrastColor: '#ffffff' }
    case 20: case '20':
      return { label: 'Administration Error', color: '#DF2901', contrastColor: '#ffffff' }
    case 21: case '21':
      return { label: 'Enforcement Error', color: '#DF2901', contrastColor: '#ffffff' }
    case 22: case '22':
      return { label: 'Syntax Error', color: '#DF2901', contrastColor: '#ffffff' }
    case 25: case '25':
      return { label: 'Risk Assessment', color: '#DF2901', contrastColor: '#ffffff' }
    case 28: case '28':
      return { label: 'Batch Error', color: '#DF2901', contrastColor: '#ffffff' }
    case 29: case '29':
      return { label: 'Data Error', color: '#DF2901', contrastColor: '#ffffff' }
    case 32: case '32':
      return { label: 'Supplementary Match Yes', color: '#29A855', contrastColor: '#ffffff' }
    case 33: case '33':
      return { label: 'Supplementary Match No', color: '#DF2901', contrastColor: '#ffffff' }
    case 200: case '200':
      return { label: 'Conveyance Arrived', color: '#29A855', contrastColor: '#ffffff' }
    case 1000: case '1000':
      return { label: 'Accepted', color: '#29A855', contrastColor: '#ffffff' }
    case 'AC':
      return { label: 'Accepted / Matched', color: '#29A855', contrastColor: '#ffffff' }
    case 'MF':  
      return { label: 'Manifest Forward', color: '#29A855', contrastColor: '#ffffff' }
    case 1001: case '1001': case 'RJ':
      return { label: 'Rejected', color: '#DF2901', contrastColor: '#ffffff' }
    case 1002: case '1002': case 'TP':  
      return { label: 'Template', color: '#0662bb', contrastColor: '#ffffff' }
    case 1003: case '1003': case 'CL':  
      return { label: 'Cancellation Accepted', color: '#FFC400', contrastColor: '#121212' }
    case 1004: case '1004': case 'CR':  
      return { label: 'Cancellation Rejected', color: '#DF2901', contrastColor: '#ffffff' }
    case 1005: case '1005': case 'DR':
      return { label: 'Draft', color: '#0662BB', contrastColor: '#ffffff' }
    case 1006: case '1006': case 'SN':
      return { label: 'SN', color: '#d3d3d3', contrastColor: '#121212' }
    case 1024: case '1024': case 'SN-TA':
      return { label: 'SN-Truck Arrived', color: '#d3d3d3', contrastColor: '#121212' }
    case 1025: case '1025': case 'SN-GR':
      return { label: 'SN-Goods Released', color: '#d3d3d3', contrastColor: '#121212' }
    case 1007: case '1007': case 'MR':  
      return { label: 'Modification Rejected', color: '#DF2901', contrastColor: '#ffffff' }
    case 1008: case '1008': case 'ST':
      return { label: 'Sent', color: '#ffc400', contrastColor: '#121212' }
    case 1009: case '1009': case 'PL':
      return { label: 'Preliminary', color: '#0662BB', contrastColor: '#ffffff' }
    case 1010: case '1010':
      return { label: 'CP', color: '#0662BB', contrastColor: '#ffffff' }
    case 1011: case '1011':
      return { label: 'Cancel', color: '#ffc400', contrastColor: '#121212' }
    case 1012: case '1012':
      return { label: 'Replace', color: '#ffc400', contrastColor: '#121212' }
    case 1013: case '1013':
      return { label: 'Confirm', color: '#ffc400', contrastColor: '#121212' }
    case 1014: case '1014':
      return { label: 'SPL', color: '#0662BB', contrastColor: '#ffffff' }
    case 1015: case '1015':
      return { label: 'Accepted With Warning', color: '#ffc400', contrastColor: '#121212' }
    case 1048: case '1048':
      return { label: 'Send Successfully', color: '#ffc400', contrastColor: '#121212' }
    case 1049: case '1049':
      return { label: 'Send Failed', color: '#29A855', contrastColor: '#ffffff' }
    case 'R':
      return { label: 'Pending Review', color: '#ffc400', contrastColor: '#121212' }
    case 'Y':
      return { label: 'Review In Progress', color: '#0662BB', contrastColor: '#ffffff' }
    case 'G':
      return { label: 'Reviewed', color: '#29A855', contrastColor: '#ffffff' }
    case 'YELLOW':
      return { label: 'Accepted', color: '#ffc400', contrastColor: '#121212' }
    case 'RED':
      return { label: 'Accepted', color: '#DF2901', contrastColor: '#ffffff' }
    default:
      return { label: status, color: '#121212', contrastColor: '#ffffff' }
  }
}

export {
  getStatusMetadata,
  getStatusMetadataMini,
  StatusStyle,
  ACEStatusStyle,
  SubmitAll,
  AttachAll,
  DisableHighwaySubmitButton,
  DettachAll,
  DisableDeleteButtonForHighway,
  SubmitMultiplefromManifest,
  DisableSubmitButton,
  DisableAttachButton,
  DisableDettachButton,
  DisableDeleteButton,
  DisableInvoiceSubmitButton,
  SubmitAndRedirect
};
